<template>
  <div>
    <ArticleFilter
      class="m-b-10"
      disable-status-filter
      show-status-select
      :articleListType="articleListType"
      :show-enabled-sites="false"
      :default-type="defaultType"
      :custom-filter-reset="resetFilters"
      :hidden-on-hp="hiddenOnHp"
    />
    <Preloader v-if="callingAPI" />
    <DataTable
      v-show="dataLoaded"
      :data="articles"
      :totalCount="totalCount"
      :page="page"
      :config="dataTableConfig"
      :selectable="selectable"
      actions-in-blank-window
      :disable-multiple-select=!multiple
      :show-select-button="showSelectButton"
      :toggleSelectGroup="toggleSelectGroup"
      @page-change="setPageAndGetRecords"
      @select-record="selectArticle"
      :max-selected="maxSelected"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import config from '@/config'
import Preloader from '../preloader/Preloader'
import ArticleFilter from './ArticleFilter'
import ArticleFilterModel from '../../model/ArticleFilter'
import DataTable from '../shared/DataTable'
import SiteService from '../../services/site/SiteService'
import RubricService from '../../services/rubric/RubricService'

export default {
  name: 'ArticleListSimple',
  components: {
    ArticleFilter,
    DataTable,
    Preloader
  },
  props: {
    showSelectButton: {
      type: Boolean,
      default: false
    },
    toggleSelectGroup: {
      type: Object,
      default: () => {}
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    },
    defaultType: {
      type: String,
      default: ''
    },
    defaultStatus: {
      type: String,
      default: 'published'
    },
    defaultSite: {
      type: Number,
      default: 0
    },
    articleListType: {
      type: String, // articles, relatedArticles
      default: 'articles'
    },
    hiddenOnHp: {
      type: Boolean,
      default: false
    },
    maxSelected: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dataLoaded: false,
      page: 1,
      search: {
        query: '',
        in: 'fulltext',
        site: null,
        publishedSince: null,
        publishedUntil: null,
        authors: []
      },
      dataTableConfig: {
        fields: {
          type: this.$t('article.list.type'),
          'field.title': this.$t('article.list.title'),
          site: this.$t('article.list.site'),
          mainRubric: this.$t('article.list.main_rubric'),
          orderDate: this.$t('article.list.order_date'),
          'setting.status': this.$t('article.list.status')
        },
        actions: {
          detail: 'article_detail'
        },
        render: {
          type: (data) => {
            if (data) {
              if (data === 'article') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-newspaper"></i></span>`
              }
              if (data === 'external') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-external-link-alt"></i></span>`
              }
              if (data === 'video') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-video"></i></span>`
              }
              if (data === 'photostory') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-images"></i></span>`
              }
              if (data === 'quiz') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-question-circle"></i></span>`
              }
              if (data === 'recipe') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-utensils"></i></span>`
              }
              if (data === 'test') {
                return `<span class="article-type-icon" title="${data}"> <i class="far fa-clipboard"></i></span>`
              }
              if (data === 'longform') {
                return `<span class="article-type-icon" title="${data}"> <i class="far fa-window-maximize"></i></span>`
              }
              if (data === 'disease') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-stethoscope"></i></span>`
              }
              if (data === 'encyclopedy') {
                return `<span class="article-type-icon" title="${data}"> <i class="fas fa-book"></i></span>`
              }
              return data
            }
          },
          'setting.status': (data) => {
            if (data === 'draft') {
              return `<span class="label label-inverse text-uppercase">${this.$t('article.status_draft')}</span>`
            } else if (data === 'review') {
              return `<span class="label label-info text-uppercase">${this.$t('article.status_review')}</span>`
            } else if (data === 'ready') {
              return `<span class="label btn-ready text-uppercase">${this.$t('article.status_ready')}</span>`
            } else if (data === 'published') {
              return `<span class="label btn-green text-uppercase">${this.$t('article.status_published')}</span>`
            } else {
              return '<span class="label label-info text-uppercase">' + data + '</span>'
            }
          },
          site: function (data) {
            if (data) {
              return SiteService.getSiteTitle(data)
            }
          },
          mainRubric: function (data) {
            if (data) {
              return RubricService.getRubricTitle(data)
            }
          }
        }
      },
      sites: [],
      selectedArticles: []
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapState(['callingAPI']),
    ...mapGetters('aiStore', ['relatedArticles', 'recommendedIds']),
    articles () {
      const fetchedArticles = this.$store.getters['article/list']
      if (this.articleListType === 'relatedArticles' && this.page === 1) {
        return [...this.relatedArticles, ...fetchedArticles]
      } else {
        return fetchedArticles
      }
    },
    totalCount () {
      return this.$store.getters['article/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['article/totalCount'] / config.article.list.limit)
    }
  },
  methods: {
    fetchArticles () {
      let fetchPromise
      if (this.articleListType === 'relatedArticles') {
        fetchPromise = this.$store.dispatch('article/fetchArticlesForRelatedList', {
          hiddenOnHp: this.hiddenOnHp ? 'hiddenOnHp' : '',
          firstPagelimit: this.vlm ? config.article.list.limit : 5
        })
      } else {
        fetchPromise = this.$store.dispatch('article/fetch', this.hiddenOnHp ? 'hiddenOnHp' : '')
      }

      fetchPromise
        .then(() => {
          this.dataLoaded = true
        })
        .catch((error) => {
          this.dataLoaded = true
          console.error(error)
        })
    },
    getArticles () {
      this.dataLoaded = false
      this.fetchArticles()
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('article/setPage', this.page)
      this.getArticles()
    },
    selectArticle (articles) {
      if (this.multiple === false) {
        this.selectedArticles = []
      }
      this.selectedArticles = articles
      this.$emit('select-record', this.selectedArticles)
    },
    addRelatedArticles () {
      this.$emit('set-data', this.selectedArticles)
      this.$emit('close')
    },
    resetFilters () {
      const filter = this._.cloneDeep(ArticleFilterModel)
      if (this.defaultStatus !== '') {
        filter.status = this.defaultStatus
      }
      if (this.defaultType !== '') {
        filter.type = this.defaultType
      }
      if (this.defaultSite !== 0) {
        filter.site = this.defaultSite
      }
      this.$store.commit('article/setPage', 1)
      this.$store.commit('article/setFilter', filter)
      this.getArticles()
    },
    close () {
      this.$emit('close')
    }

  },
  beforeDestroy () {
    this.$store.commit('article/resetFilter')
  },
  beforeMount () {
    this.sites = this.$store.getters['site/all']
    this.resetFilters()
  }
}
</script>
